<h5>Generators</h5>
<form class="col-md-8" [formGroup]="generatorForm">
    <div class="formGroup">

        <select class="form-control" id="form-env" formControlName="env">
            <option value="" disabled>Choose your environment</option>
            <option *ngFor="let env of environments" [value]="env">{{env}}</option>

        </select>

        <select class="form-control" id="form-device" formControlName="device">
            <option value="" disabled>Choose your device</option>
            <option *ngFor="let device of devices; let i = index" [value]="device">{{device}}
            </option>

        </select>

        <input class="form-control" id="form-imei" formControlName="imei" placeholder="imei *">

        <button type="submit" class="btn btn-secondary" [disabled]="!generatorForm.valid"
            (click)="onSubmit(generatorForm.value)">Send message</button>



    </div>

    <br>

    <div *ngIf="response">
        <div *ngIf="response.code == 'OK'">
            <!-- <div class="alert alert-success" role="alert"> -->
            <div [ngClass]="{'OK': 'alert alert-success', 'WARNING': 'alert alert-warning', 'ERROR': 'alert alert-danger'}[response.code]"
                role="alert">
                <pre>{{response.code}} - {{response.message}}</pre>
            </div>
        </div>

        <div *ngIf="response.code == 'ERROR'">
            <!-- <div class="alert alert-success" role="alert"> -->
            <div class="alert alert-warning" role="alert">
                {{response.body}}
            </div>
        </div>
    </div>
</form>