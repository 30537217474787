<div>
    <div *ngIf="!report" class="body-container">
        <app-v2-loading [data]="loading"></app-v2-loading>
    </div>

    <div *ngIf="report" class="body-container" [ngClass]="data.failed.length > 0 ? 'failed' : 'passed'">


        <p>This report is from the <b>{{moduleName}}</b> module, created on {{report.created_date | date:
            'yyyy-MM-dd
            HH:mm:ss': 'local'}}</p>
        <app-collapse
            [states]="[{state: 'passed', amount: data.passed.length, data:data.passed}, {state: 'failed', amount: data.failed.length, data:data.failed}]">
        </app-collapse>
    </div>
</div>