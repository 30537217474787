import { Pipe } from '@angular/core';

@Pipe({ name: 'jsonParser' })
export class JsonParser {
    transform(data: string) {

        console.log(data)

        // console.log(JSON.stringify(data))
        // console.log(JSON.parse(JSON.stringify(data, null, 3)))


        // let obj = JSON.stringify(data);
        // console.log(typeof obj)
        // console.log(obj)

        // let objJSON = JSON.parse(obj);
        // console.log(typeof objJSON)
        // console.log(objJSON)

        // const arrayAsTest = (objJSON as string).replace('[', '').replace(']', '').replace(/(},)*/g, 'b');

        // console.log(arrayAsTest)



        return JSON.parse(JSON.stringify(data, null, 3));
    }
}