import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../_services/api.service';
import { TempGeneratorInfoService } from '../../_helpers/temp-generator-info';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-v2-generators',
  templateUrl: './v2-generators.component.html',
  styleUrls: ['./v2-generators.component.css']
})
export class V2GeneratorsComponent implements OnInit {
  public devices = new TempGeneratorInfoService().returnAllDevices();
  public environments = ['DEV', 'QA', 'PROD'];
  generateForm;
  clickedDevice: string;
  clickedEnv: string;

  digitalInputsFromPreviousDevice: any[];
  random = false;
  canEnabled = false;

  response;
  jsonError: string;

  public device = {
    model: undefined,
    editable: true,
    canData: false,
    inputs: [],
    inputsAnalog: [],
    customInputs: [],
  };

  private placeHolderCanDataJSON = '\n      "id900can": "26D5000011223344",\n      "id902can": "0000000092090000"';

  get placeHolderBodyJSON(): string {
    return `{
      "digitalInput1": "On,
      "batteryVoltage": "123",
      "timestamp": "2021-01-26T08:17:49Z"   ${this.canEnabled ? this.placeHolderCanDataJSON : ''}
    }`;
  }

  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private titleService: Title) {
    this.titleService.setTitle('Jenny - Generators');
    this.generateForm = this.formBuilder.group({
      imei: ['', Validators.required],
      json: [],
      latitude: [],
      longitude: [],
      random: [],
      canEnabled: [],
    });
  }

  ngOnInit(): void {
    this.clickedDevice = this.devices[0].toString();
    this.clickedEnv = this.environments[0].toString();
    this.device = new TempGeneratorInfoService().returnDevicesInformation(this.devices[0]);

    this.digitalInputsFromPreviousDevice = [...this.device.inputs];
  }

  selectEnvironment(event): void {
    this.clickedEnv = (event.target.textContent).trim();
  }

  switchDevice(event): void {
    this.response = null;
    this.jsonError = null;
    const temporaryArray = this.digitalInputsFromPreviousDevice;

    this.clickedDevice = (event.target.textContent).trim();
    this.device = new TempGeneratorInfoService().returnDevicesInformation(this.clickedDevice);

    this.digitalInputsFromPreviousDevice = temporaryArray.slice(0, this.device.inputs.length);
  }

  inputEventHandler(id, event): void {
    if (this.digitalInputsFromPreviousDevice) {
      this.digitalInputsFromPreviousDevice[id] = event;
    }
  }

  randomToggle(event): void {
    this.random = event;
  }

  canToggle(event): void {
    this.canEnabled = event;

  }

  // TODO needs some cleaning
  async onSubmit(value): Promise<void> {
    this.jsonError = null;
    let body = {};
    this.digitalInputsFromPreviousDevice.forEach((element, index) => {
      console.log(body);
      if (this.device.model === 'GEM-OX-O') {
        body['ignition'] = element;
      }
      body['digitalInput' + (index + 1)] = `${element}`;
    });


    if (value.json) {
      try {
        body = {...JSON.parse(value.json), ...body};
        this.jsonError = '';
      } catch (e) {
        this.jsonError = e;
        this.response = null;
        await setTimeout(() => {
          this.jsonError = '';
        }, 10000);
      }
    }

    if (this.jsonError) {
      return;
    }

    console.log(value.latitude);
    console.log(value.longitude);

    if (value.latitude != null) {
      body = {...body, latitude: value.latitude};
    }
    if (value.longitude != null) {
      body = {...body, longitude: value.longitude};
    }

    if (this.random) {
      body['randomInputs'] = this.random;
    } else {
      delete body['randomInputs'];
    }

    const sendingBodyDevice = `${this.device.model}${this.canEnabled ? '-CAN' : ''}`;
    const sendingBody = {
      env: this.clickedEnv,
      device: sendingBodyDevice,
      imei: value.imei,
      body,
    };

    console.log(sendingBody);

    this.apiService.sendMessage(sendingBody).subscribe(async response => {
      this.response = JSON.parse(response);
      if (this.response['status'] === 'ok') {
        this.response['wasSend'] = `A ${sendingBody.device} message was send on ${this.clickedEnv}`;
      }
      this.response['message'] = JSON.stringify(this.response.message, null, 3);
      setTimeout(() => {
        this.jsonError = '';
      }, 10000);
    });
  }
}
