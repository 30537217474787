import { Component, Input, OnInit } from '@angular/core';

import { QuestionsAndAnswerModel } from '../../../_models/faq/questionsAndAnswers.model';

@Component({
  selector: 'app-v3-faq-question-and-answer',
  templateUrl: './v3-faq-question-and-answer.component.html',
  styleUrls: ['./v3-faq-question-and-answer.component.css']
})
export class V3FaqQuestionAndAnswerComponent implements OnInit {
  // @Input()
  // questionAndAnswers: QuestionsAndAnswerModel;
  @Input()
  index: number;

  @Input()
  questionsAndCategories;

  constructor() { }

  ngOnInit(): void {

  }

}
