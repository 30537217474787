export class TempGeneratorInfoService {

    devices = {
        'GEM-OX-L': {
            model: 'GEM-OX-L',
            inputs: new Array(3).fill(false),
            inputsAnalog: new Array(1).fill(false),
            editable: true
        },

        'GEM-OX-C': {
            model: 'GEM-OX-C',
            inputs: new Array(3).fill(false),
            inputsAnalog: new Array(0).fill(false),
            editable: true,
            canData: true
        },

        'GEM-OX-0E': {
            model: 'GEM-OX-O',
            inputs: new Array(0).fill(false),
            inputsAnalog: new Array(0).fill(false),
            customInputs: ['Ignition'],
            editable: false,
            randomInputs: false,
        },

        'GEM-OX-G': {
            model: 'GEM-OX-G',
            inputs: new Array(0).fill(false),
            inputsAnalog: new Array(0).fill(false),
            editable: false,
            randomInputs: false,
            misc: 'Values are always on'
        },

        'TrackUnit': {
            model: 'TrackUnit',
            inputs: new Array(4).fill(false),
            inputsAnalog: new Array(0).fill(false),
            editable: false
        },

        'Scombox': {
            model: 'Scombox',
            inputs: new Array(9).fill(false),
            inputsAnalog: new Array(0).fill(false),
            editable: false
        },

        'Genie': {
            model: 'Genie',
            inputs: new Array(1).fill(false),
            inputsAnalog: new Array(0).fill(false),
            editable: true
        },

        'JLG': {
            model: 'JLG',
            inputs: new Array(1).fill(false),
            inputsAnalog: new Array(0).fill(false),
            editable: true
        },

        'DEUTZ': {
            model: 'DEUTZ',
            inputs: new Array(1).fill(false),
            inputsAnalog: new Array(0).fill(false),
            editable: true
        },

        'HYSTER': {
            model: 'HYSTER',
            inputs: new Array(3).fill(false),
            inputsAnalog: new Array(0).fill(false),
            editable: true
        },

        'STILL': {
            model: 'STILL',
            inputs: new Array(3).fill(false),
            inputsAnalog: new Array(0).fill(false),
            editable: true
        },
    }

    returnAllDevices() {
        return Object.keys(this.devices);
    }


    returnDevicesInformation(device: string) {
        return this.devices[device];
    }
}
