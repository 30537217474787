import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_helpers/auth.guard';
import { NgModule } from '@angular/core';
import { TestDetailComponent } from './_components/test-detail/test-detail.component';
import { V2AccountComponent } from './_components/v2-account/v2-account.component';
import { V2DashboardComponent } from './_components/v2-dashboard/v2-dashboard.component';
import { V2FaqComponent } from './_components/v2-faq/v2-faq.component';
import { V2GeneratorsComponent } from './_components/v2-generators/v2-generators.component';
import { V2HomeComponent } from './_components/v2-home/v2-home.component';
import { V2LoginComponent } from './_components/v2-login/v2-login.component';
import { V2SearchComponent } from './_components/v2-search/v2-search.component';
import { V3DashboardComponent } from './_components/v3-dashboard/v3-dashboard.component';

const routes: Routes = [
  { path: '', component: V2HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: V2LoginComponent },
  { path: 'v2/home', component: V2HomeComponent, canActivate: [AuthGuard] },
  { path: 'v2/account', component: V2AccountComponent, canActivate: [AuthGuard] },
  { path: 'v2/dashboard', component: V2DashboardComponent, canActivate: [AuthGuard] },
  { path: 'v2/search', component: V2SearchComponent, canActivate: [AuthGuard] },
  { path: 'v2/generators', component: V2GeneratorsComponent, canActivate: [AuthGuard] },
  { path: 'v2/dashboard/:name/:id', component: TestDetailComponent, canActivate: [AuthGuard] },
  { path: 'v2/faq', component: V2FaqComponent, canActivate: [AuthGuard] },
  { path: 'v3/dashboard', component: V3DashboardComponent, canActivate: [AuthGuard] },
  { path: 'v3/dashboard/:name/:id', component: TestDetailComponent, canActivate: [AuthGuard] },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
