import { Component, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { FaqModulesService } from '../../_services/faq/faq-modules.service';
import { FaqQuestionsAndAnswersService } from '../../_services/faq/faq-questions-and-answers.service';
import { FaqSubcategoriesService } from '../../_services/faq/faq-subcategories.service';
import { ModulesModel } from '../../_models/faq/modules.model';
import { Observable } from 'rxjs';
import { QuestionsAndAnswerModel } from '../../_models/faq/questionsAndAnswers.model';
import { SubcategoryModal } from '../../_models/faq/subcategories.modal';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-v2-faq',
  templateUrl: './v2-faq.component.html',
  styleUrls: ['./v2-faq.component.css']
})
export class V2FaqComponent implements OnInit {
  clickedSubject: { name, id };
  subjects = [];
  modules$: Observable<ModulesModel[]>;
  questionsAndAnswers$: Observable<QuestionsAndAnswerModel[]>;
  subcategories$: Observable<SubcategoryModal[]>;
  categories: SubcategoryModal[] = [];
  questions: QuestionsAndAnswerModel[] = [];
  loading = true;
  team;

  constructor(
    private faqModulesService: FaqModulesService,
    private faqQuestionsAndAnswersService: FaqQuestionsAndAnswersService,
    private faqSubcategoriesService: FaqSubcategoriesService,
    private titleService: Title) {
    this.titleService.setTitle('Jenny - FAQ');
  }

  ngOnInit(): void {
    this.getModules();
  }

  switchSubject(subject) {
    this.loading = true;
    this.categories = [];
    this.clickedSubject = subject;
    this.team = subject.team;
    this.getQuestionsAndAnswers(this.clickedSubject.id);
  }

  getModules() {
    const translate = {
      trackAndTrace: 'Track and Trace',
      fleet: 'Fleet',
      service: 'Service',
      company: 'Company',
      accessAndSafety: 'Access and Safety',
      alerts: 'Alerts',
      reporting: 'Reporting',
    };
    this.modules$ = this.faqModulesService.getAllModulesAndStates().pipe(
      map((modules) => {
        return modules.map((module) => ({ ...module, name: translate[module.name] || module.name }));
      }),
      tap((modules) => {
        this.switchSubject(modules[0]);
      }));
  }

  getQuestionsAndAnswers(id: string) {
    this.questionsAndAnswers$ = this.faqQuestionsAndAnswersService.getAllQuestionsAndAnswersForModule(id).pipe(
      tap(questionsAndAnswers => {
        this.questions = questionsAndAnswers;
        this.getSubcategories(this.clickedSubject.id);
      })
    );
  }

  getSubcategories(id: string) {

    this.subcategories$ = this.faqSubcategoriesService.getAllSubcategoriesForModule(id).pipe(
      map(subcategories => {
        this.categories = subcategories;
        this.questions = this.questions.map(question => {
          let category = 'unnamed';
          if (question.subcategory_id === null) {
            this.categories.push({ id: '0', module_id: '0', name: 'unnamed' });
          } else {
            category = this.categories.find(category => question.subcategory_id === category.id).name;
          } return { ...question, category };
        });

        this.categories = this.categories.map(category => {
          const filteredQuestions = this.questions.filter(question => question.category === category.name);
          return { ...category, questions: filteredQuestions }
        });
        this.loading = false;

        this.categories.sort((a, b) => (a.name > b.name) ? 1 : -1);

        return this.categories;
      })
    );
  }
}
