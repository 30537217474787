<div class="container-fluid">

    <div class="row" *ngIf="states">
        <div class="col-md-6" *ngFor="let state of states, let i = index">
            <div id="card-{{state.state}}-{{i}}">
                <div class="card">
                    <div class="card-header">
                        <a class="card-link uppercase" data-toggle="collapse"
                            attr.data-parent="#card-{{state.state}}-{{i}}"
                            href="#card-element-{{state.state}}-{{i}}">{{state.state}} - Amount of test:
                            {{state.amount}}</a>
                    </div>
                    <div id="card-element-{{state.state}}-{{i}}" class="collapse show">
                        <div class="card-body">

                            <!-- {{state.data | json}} -->
                            <div class="col-md" *ngFor="let test of state.data, let i = index">
                                <div id="card-{{state.state}}-{{i}}-{{i}}">
                                    <div class="card">
                                        <div class="card-header box">
                                            <a class="card-link" data-toggle="collapse"
                                                attr.data-parent="#card-{{state.state}}-{{i}}-{{i}}"
                                                href="#card-element-{{state.state}}-{{i}}-{{i}}">
                                                <h6>{{test.name}}</h6>
                                            </a>
                                            <div class="right">
                                                <i *ngIf="state.state === 'passed'"
                                                    class="bi bi-check-square pass square "></i>
                                                <i *ngIf="state.state === 'failed'"
                                                    class="bi bi-x-square fail square "></i>
                                            </div>

                                        </div>
                                        <div id="card-element-{{state.state}}-{{i}}-{{i}}" class="collapse">
                                            <div class="card-body">

                                                <a data-toggle="collapse"
                                                    attr.data-parent="#card-{{state.state}}-{{i}}-{{i}}-steps"
                                                    href="#img-element-{{state.state}}-{{i}}-steps"
                                                    class="collapse show">
                                                    <h6>Steps:</h6>
                                                    <br *ngIf="test.error">
                                                </a>
                                                <div id="img-element-{{state.state}}-{{i}}-steps" class="collapse">
                                                    <br *ngIf="!test.error">
                                                    <div *ngFor="let step of test.steps">
                                                        <div class="box">
                                                            <p>Name: {{step.title}}</p>
                                                            <p class="right">Duration: {{step.duration / 1000}}s</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="state.state === 'failed'" class="error-stack">

                                                    <a data-toggle="collapse"
                                                        attr.data-parent="#card-{{state.state}}-{{i}}-{{i}}-error"
                                                        href="#img-element-{{state.state}}-{{i}}-error" class="">
                                                        <h6>Error:</h6>
                                                    </a>
                                                    <div id="img-element-{{state.state}}-{{i}}-error"
                                                        class="collapse show">
                                                        <br>
                                                        <p>{{test.error.stack}}</p>
                                                        <div>
                                                            <a data-toggle="collapse"
                                                                attr.data-parent="#card-{{state.state}}-{{i}}-{{i}}"
                                                                href="#img-element-{{state.state}}-{{i}}"
                                                                class="btn btn-primary scrnsht">
                                                                Screenshot
                                                            </a>


                                                            <div id="img-element-{{state.state}}-{{i}}"
                                                                class="collapse">
                                                                <img src="data:image/png;base64,{{test.img}}"
                                                                    alt="Report" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br *ngIf='state.amount > 1'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>