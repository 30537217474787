import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../_services/api.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-v2-home',
  templateUrl: './v2-home.component.html',
  styleUrls: ['./v2-home.component.css']
})
export class V2HomeComponent implements OnInit {
  information;

  constructor(private apiService: ApiService, private titleService: Title) {
    this.titleService.setTitle('Jenny');
  }

  ngOnInit(): void {
    this.apiService.getHomeInfo().subscribe(info => {
      this.information = info;
    });
  }
}
