import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ReportService } from '@app/_services/reports/report.service';

@Component({
  selector: 'app-v3-dashboard-reports',
  templateUrl: './v3-dashboard-reports.component.html',
  styleUrls: ['./v3-dashboard-reports.component.css']
})
export class V3DashboardReportsComponent implements OnInit {
  moduleId: string;
  moduleName: string;
  reports = [];

  constructor(private activatedRoute: ActivatedRoute, private reportService: ReportService) {
    this.moduleName = this.activatedRoute.snapshot.paramMap.get('name');
    this.moduleId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.reportService.getAllReportsForModuleBasedOnModuleId(this.moduleId).subscribe(rep => {
      this.reports = rep;
      console.log(this.reports);
    });
  }

}
