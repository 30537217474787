import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-button',
  templateUrl: './input-button.component.html',
  styleUrls: ['./input-button.component.css']
})
export class InputButtonComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() name;
  @Input() formControlInputName: string;
  @Output() stateEvent = new EventEmitter<boolean>()
  state = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onChange() {
    this.state = !this.state;
    this.stateEvent.emit(this.state);
  }
}
