import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.css']
})
export class CollapseComponent implements OnInit {
  @Input()
  states: Array<{ state, amount, data }>;

  constructor() { }

  ngOnInit(): void {
    console.log(this.states);
  }

}
