<div class="home-container">



    <div class="body-container asset">
        <p>Search for an asset</p>


        <form class="col-md-8" [formGroup]="searchAssetForm" id="searchAssetForm">

            <div class="form-group">
                <input class="form-control" id="assetValue" formControlName="assetValue" placeholder="asset *">
            </div>

            <button type="submit" class="btn btn-secondary" [disabled]="!searchAssetForm.valid"
                (click)="onSubmit(searchAssetForm.value)">Get info</button>
        </form>
    </div>

    <div class="body-container device">
        <p>Search for a device</p>

        <form class="col-md-8" [formGroup]="searchDeviceForm" id="searchDeviceForm">

            <div class="form-group">
                <input class="form-control" id="deviceValue" formControlName="deviceValue" placeholder="imei *">
            </div>

            <button type="submit" class="btn btn-secondary" [disabled]="!searchDeviceForm.valid"
                (click)="onSubmit(searchDeviceForm.value)">Get info</button>
        </form>
    </div>

</div>