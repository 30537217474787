import { Component, Input, OnInit } from '@angular/core';

import { Module } from '@app/_models/module';
import { formatDate } from '@angular/common';
import moment from 'moment';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  @Input()
  module: Module;
  state: string;
  date: string;
  timeDifferenceGreaterThan1Hour = false;

  constructor() { }

  ngOnInit(): void {
    if (this.module.name === 'serviceAndMaintenance') {
      this.module.name = 'S&M';
    }
    if (this.module.state) {
      this.state = 'Passed';
    } else { this.state = 'Failed'; }
    this.date = formatDate(this.module.updated_at, 'dd-MM-YYYY HH:mm:ss', 'en-US');

    if (moment(moment(this.date, 'DD-MM-YYYY HH:mm:ss')).isBefore(moment().subtract(1, 'hours'))) {

      this.timeDifferenceGreaterThan1Hour = true;
    }
  }

}
