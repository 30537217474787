<div class="body-container">


    <app-v2-loading [data]="modules"></app-v2-loading>

    <div class="d-flex align-content-stretch flex-wrap modules">

        <app-card *ngFor="let module of modules" [module]='module'>
        </app-card>
    </div>

    <hr>

    <div class="alert alert-light" role="alert">
        <svg height="10" width="10">
            <circle cx="5" cy="5" r="5" fill="#bafc00" />
        </svg> - The last test iteration passed <br>
        <svg height="10" width="10">
            <circle cx="5" cy="5" r="5" fill="#fc3200" />
        </svg> - The last test iteration failed<br>
        <svg height="10" width="10">
            <circle cx="5" cy="5" r="5" fill="#fca400" />
        </svg> - The last test iteration has been more than 1 hour ago

    </div>
</div>