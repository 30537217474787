import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-v2-loading',
  templateUrl: './v2-loading.component.html',
  styleUrls: ['./v2-loading.component.css']
})
export class V2LoadingComponent implements OnInit {
  @Input()
  data;

  constructor() { }

  ngOnInit(): void {
    console.log(this.data)
  }
}
