import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-v2-search',
  templateUrl: './v2-search.component.html',
  styleUrls: ['./v2-search.component.css']
})
export class V2SearchComponent implements OnInit {
  public searchAssetForm;
  public searchDeviceForm;

  constructor(private formBuilder: FormBuilder, private titleService: Title) {
    this.titleService.setTitle('Jenny - Search');
  }

  ngOnInit(): void {
    this.searchAssetForm = this.formBuilder.group({
      assetValue: ['', Validators.required],
    });

    this.searchDeviceForm = this.formBuilder.group({
      deviceValue: ['', Validators.required],
    });
  }

  updateSearchType(blub: string) {

  }

  onSubmit(data) {

  }

}
