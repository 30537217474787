<div class="header" *ngIf="questionsAndCategories.name != 'unnamed'">
    <div id="header-{{questionsAndCategories.name}}">
        <button class="btn btn-link" type="button" data-toggle="collapse"
            [attr.data-target]="'#collapseQuestions' + questionsAndCategories.name" aria-expanded="true"
            [attr.aria-controls]="'collapseQuestions' + questionsAndCategories.name">
            <h4>{{questionsAndCategories.name}} </h4>
        </button>
    </div>


    <div id="collapseQuestions{{questionsAndCategories.name}}" class="collapse">
        <app-v2-faq-card *ngFor="let question of questionsAndCategories.questions; let i = index"
            [card]="questionsAndCategories.name + '-' + i" [title]="question.question" [response]="question.answer">
        </app-v2-faq-card>
    </div>
</div>

<div *ngIf="questionsAndCategories.name == 'unnamed'">
    <hr *ngIf="index != 0">

    <app-v2-faq-card *ngFor="let question of questionsAndCategories.questions; let i = index"
        [card]="questionsAndCategories.name + '-' + i" [title]="question.question" [response]="question.answer">
    </app-v2-faq-card>
</div>