<div class="body-container">


    <div class="table-responsive">
        <table class="table table-hover table-bordered">
            <thead>
                <tr>
                    <th scope="col" class="name">Name</th>
                    <th scope="col">Created date</th>
                    <th scope="col" class="url">Report</th>
                    <th scope="col" class="trash">
                        <div *ngIf="">
                            <span class="material-icons btn dashboard-btn">
                                edit
                            </span>
                        </div>
                        <div *ngIf="">
                            <span class="material-icons btn dashboard-btn">
                                cancel
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr [ngClass]="" *ngFor="let report of reports; let i = index" id="table">
                    <td>{{report.name}}</td>
                    <td>{{report.created_date | date: 'yyyy-MM-dd HH:mm:ss': 'local' }}</td>
                    <td><a [routerLink]="['/v3/dashboard', moduleName, report.module_id, report.id]">{{report.url}}</a>
                    </td>
                    <td>
                        <div class="form-check padding" *ngIf="">
                            <input class="form-check-input position-static checkbox select" type="checkbox"
                                id="checkbox-" value="option1" aria-label="..." [attr.data-checklist-id]="">
                        </div>

                        <div *ngIf="">
                            <span class="material-icons btn dashboard-btn">
                                delete
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="delete-button" *ngIf="">
            <button type="button" class="btn btn-secondary">Delete
                selected</button>
        </div>


        <app-v2-loading [data]="reports"></app-v2-loading>
    </div>
</div>