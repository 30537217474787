<div class="header">
    <div id="header--{{header}}">
        <button class="btn btn-link" type="button" data-toggle="collapse"
            [attr.data-target]="'#collapseQuestions' + header" aria-expanded="true"
            [attr.aria-controls]="'collapseQuestions' + header">
            <h4>{{header}} </h4>
        </button>
    </div>


    <div id="collapseQuestions{{header}}" class="collapse">
        <app-v2-faq-card *ngFor="let question of questions[header]; let i = index" [card]="header + '-' + i"
            [title]="question.question" [response]="question.answer">
        </app-v2-faq-card>
    </div>
</div>