import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubcategoryModal } from '@app/_models/faq/subcategories.modal';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqSubcategoriesService {

  constructor(private http: HttpClient) { }

  getAllSubcategoriesForModule(id: string) {
    return this.http.get<SubcategoryModal[]>(`${environment.apiUrl}/api/faq/subcategories/${id}`);
  }
}
