<div class="body-container">
    <div class="btn-group btn-devices btn-header scrollmenu" role="group" aria-label="Basic example">
        <div *ngFor="let subject of modules$ | async; let i = index" class="button btn-header">
            <button id="btn-subject-{{i}}" type="button" (click)="switchSubject(subject)"
                [ngClass]="clickedSubject.name == subject.name? 'jenny-button active' : 'jenny-button'">
                {{subject.name}} </button>
        </div>
    </div>
    <div class="team-title">
        <h3 *ngIf="team">Responsible team: {{team}}</h3>
    </div>
    <hr>
    <app-v2-loading [data]="categories"></app-v2-loading>

    <div *ngIf='questionsAndAnswers$ | async;'>
        <div *ngFor='let questionAndAnswer of subcategories$| async; let i = index'>
            <app-v3-faq-question-and-answer [questionsAndCategories]="questionAndAnswer" [index]="i">
            </app-v3-faq-question-and-answer>
        </div>
    </div>
</div>