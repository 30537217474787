import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '@app/_services/dashboard/dashboard.service';
import { History } from '@app/_models/history';

@Component({
  selector: 'app-test-detail',
  templateUrl: './test-detail.component.html',
  styleUrls: ['./test-detail.component.css']
})
export class TestDetailComponent implements OnInit {
  histories: History[];
  moduleId: string;
  moduleName: string;
  passFail = { state: null, fail: 0, pass: 0, ratio: null };
  edit = false;

  constructor(
    private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.moduleId = this.activatedRoute.snapshot.paramMap.get('id');
    this.moduleName = this.activatedRoute.snapshot.paramMap.get('name');

    this.dashboardService.getDetailedModule(this.moduleId).subscribe(data => {
      this.histories = data;

      this.dashboardService.getPassFailFromModule(this.moduleId).subscribe((data: { fail_count, success_count }) => {
        this.passFail.state = 'ok';
        this.passFail.fail = +data.fail_count;
        this.passFail.pass = +data.success_count;
        this.passFail.ratio = (this.passFail.fail / (this.passFail.fail + this.passFail.pass) * 100).toFixed(0);
        if (this.passFail.ratio === 'NaN') {
          this.passFail.ratio = 0;
        }
      });

    });
  }

  delete(id: string): void {
    this.dashboardService.deleteHistory(id).subscribe(() => {
      window.location.reload();
    });
  }

  toggle(): void {
    this.edit ? this.edit = false : this.edit = true;
  }

  deleteSelectedRows(): void {
    const idArray = [];
    document.querySelectorAll('#table .select:checked').forEach(e => {
      idArray.push(e.getAttribute('data-checklist-id'));
    });

    this.dashboardService.deleteMultipleHistories(idArray).subscribe(() => {
      window.location.reload();
    });
  }

  clearRatio() {
    this.dashboardService.deleteAllModuleHistories(this.moduleId).subscribe(() => {
      this.dashboardService.resetPassFailRatio(this.moduleId).subscribe(() => {
        window.location.reload();
      });
    });
  }
}
