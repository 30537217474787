import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModulesModel } from '@app/_models/faq/modules.model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqModulesService {

  constructor(private http: HttpClient) { }

  getAllModulesAndStates() {
    return this.http.get<ModulesModel[]>(`${environment.apiUrl}/api/faq/modules`);
  }

}
