import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BasicAuthInterceptor } from './_helpers/basic-auth.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { CardComponent } from './_components/card/card.component';
import { CollapseComponent } from './_components/collapse/collapse.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { InputButtonComponent } from './_components/input-button/input-button.component';
import { JsonParser } from './_pipes/json-parser';
import { NgModule } from '@angular/core';
import { TestDetailComponent } from './_components/test-detail/test-detail.component';
import { V2AccountComponent } from './_components/v2-account/v2-account.component';
import { V2DashboardComponent } from './_components/v2-dashboard/v2-dashboard.component';
import { V2FaqCardComponent } from './_components/v2-faq/v2-faq-card/v2-faq-card.component';
import { V2FaqComponent } from './_components/v2-faq/v2-faq.component';
import { V2FaqDefaultQuestionAndAnswerComponent } from './_components/v2-faq/v2-faq-default-question-and-answer/v2-faq-default-question-and-answer.component';
import { V2FaqSubcategoryQuestionAndAnswerComponent } from './_components/v2-faq/v2-faq-subcategory-question-and-answer/v2-faq-subcategory-question-and-answer.component';
import { V2GeneratorsComponent } from './_components/v2-generators/v2-generators.component';
import { V2HomeComponent } from './_components/v2-home/v2-home.component';
import { InputButtonComponent as V2InputButtonComponent } from './_components/v2-generators/input-button/input-button.component';
import { V2LoadingComponent } from './_components/v2-loading/v2-loading.component';
import { V2LoginComponent } from './_components/v2-login/v2-login.component';
import { V2NavigationMenuComponent } from './_components/v2-navigation-menu/v2-navigation-menu.component';
import { V2QuickToolsComponent } from './_components/v2-quick-tools/v2-quick-tools.component';
import { V2SearchComponent } from './_components/v2-search/v2-search.component';
import { V3CardsComponent } from './_components/v3-dashboard/v3-cards/v3-cards.component';
import { V3DashboardComponent } from './_components/v3-dashboard/v3-dashboard.component';
import { V3DashboardReportDetailComponent } from './_components/v3-dashboard-report-detail/v3-dashboard-report-detail.component';
import { V3DashboardReportsComponent } from './_components/v3-dashboard-reports/v3-dashboard-reports.component';
import { V3FaqQuestionAndAnswerComponent } from './_components/v2-faq/v3-faq-question-and-answer/v3-faq-question-and-answer.component';
import { fakeBackendProvider } from './_helpers/fake-backend';

@NgModule({
  declarations: [
    AppComponent,
    InputButtonComponent,
    CardComponent,
    TestDetailComponent,
    V2HomeComponent,
    V2NavigationMenuComponent,
    V2AccountComponent,
    V2QuickToolsComponent,
    V2DashboardComponent,
    V2LoadingComponent,
    V2SearchComponent,
    V2GeneratorsComponent,
    V2InputButtonComponent,
    V2FaqComponent,
    V2FaqDefaultQuestionAndAnswerComponent,
    V2FaqSubcategoryQuestionAndAnswerComponent,
    V2FaqCardComponent,
    V2LoginComponent,
    V3FaqQuestionAndAnswerComponent,
    V3DashboardComponent,
    V3DashboardReportsComponent,
    V3DashboardReportDetailComponent,
    V3CardsComponent,
    CollapseComponent,
    JsonParser
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
