import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ReportModel } from '@app/_models/report.model';
import { ReportService } from '@app/_services/reports/report.service';

@Component({
  selector: 'app-v3-dashboard-report-detail',
  templateUrl: './v3-dashboard-report-detail.component.html',
  styleUrls: ['./v3-dashboard-report-detail.component.css']
})
export class V3DashboardReportDetailComponent implements OnInit {
  moduleName: string;
  reportId: string;
  report: ReportModel;
  data: { passed, failed };
  loading = '';

  constructor(private activatedRoute: ActivatedRoute, private reportService: ReportService) {
    this.moduleName = this.activatedRoute.snapshot.paramMap.get('name');
    this.reportId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.reportService.getReportById(this.reportId).subscribe(rep => {

      this.report = rep[0];

      this.data = JSON.parse(this.report.data);
      console.log(this.data);
      this.loading = 'false';

      console.log(this.report.created_date);
      console.log(this.report.created_date.toLocaleString('en-US', { timeZone: 'Europe/Brussels' }));
      console.log(new Date(this.report.created_date));
    });
  }

}
