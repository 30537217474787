import { Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { AuthenticationService } from '../../_services/authentication.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-v2-account',
  templateUrl: './v2-account.component.html',
  styleUrls: ['./v2-account.component.css'],
})
export class V2AccountComponent implements OnInit {
  username$: Observable<string>;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private titleService: Title) {

  }

  ngOnInit(): void {
    this.username$ = this.authenticationService.currentUser.pipe(
      filter((user) => !!user),
      map((user) => {
        this.titleService.setTitle(`Jenny - ${user.first_name}${user.surname}`);
        return `${user.first_name}${user.surname}`;
      })
    );
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
