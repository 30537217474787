<div class="jenny-navbar">
  <a class="jenny-logo-link-big" href="v2/home">
    <img class="jenny-logo-img-big" src="assets/img/jenny.png" />
  </a>
  <nav class="navbar navbar-expand-lg navbar-light ">

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <!-- <span class="navbar-toggler-icon"></span> -->
      <!-- <a class="jenny-logo-link" href="v2/home"> -->
      <img class="jenny-logo-img" src="assets/img/jenny.png" />
      <!-- </a> -->
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav left mr-auto">
        <li class="nav-item home" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/v2/home']">Home <span class="sr-only">(current)</span></a>
        </li>

        <li class="nav-item big" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/v2/generators']">Generators</a>
        </li>

        <li class="nav-item big" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/v2/dashboard']">Dashboard</a>
        </li>

        <li class="nav-item big" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/v3/dashboard']">Dashboard (PW)</a>
        </li>

        <li class="nav-item big disabled" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/v2/search']">Search</a>
        </li>
      </ul>
      <ul class="navbar-nav right ml-auto">

        <li class="nav-item small-width" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/v2/faq']">FAQ</a>
        </li>

        <li class="nav-item small-width " data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" [routerLink]="['/v2/account']">{{
            username$ | async
            }}</a>
        </li>
      </ul>
    </div>
  </nav>
</div>