import { History } from '@app/_models/history';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Module } from '@app/_models/module';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  headers: {};

  constructor(private http: HttpClient) { }

  getAllModulesAndStates() {
    return this.http.get<Module[]>(`${environment.apiUrl}/api/dashboard/modulesStates`);
  }

  getDetailedModule(id: string) {
    return this.http.get<History[]>(`${environment.apiUrl}/api/dashboard/${id}`);
  }

  getPassFailFromModule(id: string) {
    return this.http.get(`${environment.apiUrl}/api/dashboard/history/${id}/passFailRatio`);
  }

  updatePassFail(id: string, state: boolean) {
    return this.http.post(`${environment.apiUrl}/api/dashboard/${id}/passFail`, { state }, { headers: this.headers });
  }

  resetPassFailRatio(id: string) {
    return this.http.put(`${environment.apiUrl}/api/dashboard/${id}/passFail/reset`, {});
  }

  deleteHistory(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/dashboard/history/${id}`);
  }

  deleteAllModuleHistories(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/dashboard/history/${id}/deleteAllHistories`);
  }

  deleteMultipleHistories(ids: string[]) {
    return this.http.request('delete', `${environment.apiUrl}/api/dashboard/history/`, { body: ids });
  }
}
