import * as moment from 'moment';

import { Component, Input, OnInit } from '@angular/core';

import { Module } from '../../../_models/module';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-v3-cards',
  templateUrl: './v3-cards.component.html',
  styleUrls: ['./v3-cards.component.css']
})
export class V3CardsComponent implements OnInit {

  @Input()
  module: Module;
  state: string;
  date: string;
  timeDifferenceGreaterThan1Hour = false;

  constructor() { }

  ngOnInit(): void {
    if (this.module.name === 'serviceAndMaintenance')
      this.module.name = 'S&M';
    if (this.module.state) {
      this.state = 'Passed';
    } else { this.state = 'Failed'; }
    this.date = formatDate(this.module.updated_at, 'dd-MM-YYYY HH:mm:ss', 'en-US');

    if (moment(moment(this.date, 'DD-MM-YYYY HH:mm:ss')).isBefore(moment().subtract(1, 'hours'))) {

      this.timeDifferenceGreaterThan1Hour = true;
    }
  }

}
