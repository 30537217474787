import { User, UserObject } from '@app/_models/user';
import { catchError, map } from 'rxjs/operators';

import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUser = new BehaviorSubject<UserObject>(null);
  isLoggedIn: boolean = false;
  public redirectUrl: string;

  constructor(private http: HttpClient) {
    /** TODO check if we want to keep setting user from local storage.
     * In fdm-ui we get user on load with /me call, this way we always have latest data
     * In this code you only get latest data when loging in again.
     */
    this.currentUser.next(JSON.parse(localStorage.getItem('loginResponse')));
  }

  public get currentUserValue(): UserObject {
    return this.currentUser.value;
  }

  async login(username: string, password: string, env: string) {
    return this.http
      .post(`${environment.apiUrl}/api/auth/login`, { username, password, env })
      .pipe(
        map((loginResponse: { status: 'OK' | 'Error'; token: User; error }) => {
          if (loginResponse.status === 'Error') {
            throw loginResponse.error;
          }

          localStorage.setItem('loginResponse', JSON.stringify(loginResponse.token.user));
          localStorage.setItem('token', loginResponse.token.token);
          localStorage.setItem('refresh_token', loginResponse.token.refresh_token);
          this.currentUser.next(loginResponse.token.user);
          this.isLoggedIn = true;

          return loginResponse.token.user;
        })
      );
  }

  refreshUser(): Observable<string> {
    return this.http
      .put(`${environment.fmdApi}api/auth/refresh`, {
        refresh_token: this.getRefreshToken()
      })

      .pipe(catchError(() => { throw this.logout() }), map((refreshResponse: { token: string }) => {
        localStorage.setItem('token', refreshResponse.token);
        return refreshResponse.token;
      }));
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  logout(): void {
    localStorage.removeItem('loginResponse');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    this.currentUser.next(null);
    this.isLoggedIn = false;
  }

  private getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }
}
