import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-v2-faq-card',
  templateUrl: './v2-faq-card.component.html',
  styleUrls: ['./v2-faq-card.component.css']
})
export class V2FaqCardComponent implements OnInit {
  @Input()
  card: string;
  @Input()
  title: string;
  @Input()
  response: string;
  @Input()
  responses: string[];

  constructor() { }

  ngOnInit(): void {
  }

}
