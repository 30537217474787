import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-v2-quick-tools',
  templateUrl: './v2-quick-tools.component.html',
  styleUrls: ['./v2-quick-tools.component.css']
})
export class V2QuickToolsComponent implements OnInit {
  public generatorForm;
  public response = { code: null, message: undefined, body: undefined };

  public environments = ['DEV', 'QA', 'PROD'];
  public devices = ['GEM-OX-L', 'GEM-OX-C', 'GEM-OX-G'];

  constructor(private formBuilder: FormBuilder, private apiService: ApiService) { }

  ngOnInit(): void {
    this.generatorForm = this.formBuilder.group({
      env: [this.environments[0], Validators.required],
      device: [this.devices[0], Validators.required],
      imei: ['', Validators.required],
    });
  }

  onSubmit(message) {
    console.log(message);
    message['body'] = {};

    this.apiService.sendMessage(message)
      .subscribe(async data => {
        const dataJSON: any = JSON.parse(data);
        console.log(dataJSON)
        if (dataJSON.status.includes('ok')) {
          this.response.code = 'OK';
          this.response.message = JSON.stringify(dataJSON.message, null, 3);
          await setTimeout(() => {
            this.response.code = '';
          }, 10000);

        } else {
          this.response.code = 'ERROR';
        }
      });

  }

}
