import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportModel } from '../../_models/report.model';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  getAllReportsForModuleBasedOnModuleId(moduleId: string) {
    return this.http.get<ReportModel[]>(`${environment.apiUrl}/api/report/module/${moduleId}`);
  }

  getReportById(reportId: string) {
    return this.http.get<ReportModel>(`${environment.apiUrl}/api/report/${reportId}`);
  }
}
