import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { AuthenticationService } from '@app/_services/authentication.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-v2-login',
  templateUrl: './v2-login.component.html',
  styleUrls: ['./v2-login.component.css']
})
export class V2LoginComponent implements OnInit {
  loginForm;
  returnUrl;
  error;

  submitted = false;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private titleService: Title) {
    this.titleService.setTitle('Jenny - Login');
  }

  get f() { return this.loginForm.controls; }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/v2/home';
    this.checkLogin();
  }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    const test = await this.authenticationService.login(this.f.username.value, this.f.password.value, 'qa');
    test.subscribe(
      data => {
        this.router.navigate([this.returnUrl]);
      },
      async error => {
        this.error = 'This email password does not match, please try again';
        this.loading = false;

        setTimeout(() => {
          this.error = '';

        }, 10000);
      }
    );
  }

  checkLogin(): boolean {
    if (this.authenticationService.isLoggedIn) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
