import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-v2-faq-default-question-and-answer',
  templateUrl: './v2-faq-default-question-and-answer.component.html',
  styleUrls: ['./v2-faq-default-question-and-answer.component.css']
})
export class V2FaqDefaultQuestionAndAnswerComponent implements OnInit {
  @Input()
  question;

  constructor() { }

  ngOnInit(): void {
    console.log(this.question)
  }

}
