<div class="card">
    <div class="card-header" id="headingOne">
        <h5 class="mb-0">
            <button class="btn btn-link" id="title" type="button" data-toggle="collapse"
                attr.data-target="#question-{{card}}" aria-controls="question" aria-expanded="false">
                <p>{{title}}</p>
            </button>
        </h5>
    </div>

    <div id="question-{{card}}" class="collapse" aria-labelledby="headingOne" aria-expanded="false">
        <div class="card-body">
            <p *ngIf="response" [innerHTML]="response"></p>
        </div>
    </div>
</div>