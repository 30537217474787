import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-v2-input-button',
  templateUrl: './input-button.component.html',
  styleUrls: ['./input-button.component.css']
})
export class InputButtonComponent implements OnInit {
  @Input()
  public name;
  @Input()
  public parentForm;
  @Output() stateEvent = new EventEmitter<boolean>();
  public onOff = false;

  constructor() { }

  ngOnInit(): void {
    this.stateEvent.emit(this.onOff);
  }

  switchState() {
    this.onOff = !this.onOff;
    this.stateEvent.emit(this.onOff);
  }

}
