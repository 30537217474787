import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { AuthenticationService } from '@app/_services/authentication.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }
    helper = new JwtHelperService();

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.authenticationService.currentUserValue || !this.authenticationService.getToken() || request.url.includes('/api/auth/refresh')) {
            // if no token, just return request without adding bearer token
            return next.handle(request);
        }

        if (this.isTokenExpired(this.authenticationService.getToken())) {
            // if token is expired -> get new token and set header
            return (this.authenticationService.refreshUser()).pipe(
                switchMap((newToken: string) => {
                    return next.handle(this.setAuthorizationHeader(request, newToken));
                })
            );
        }

        // if user && token is valid, set token to header
        return next.handle(
            this.setAuthorizationHeader(request, this.authenticationService.getToken())
        );
    }

    private isTokenExpired(token: string) {
        return token ? this.helper.isTokenExpired(token) : false;
    }

    private setAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
