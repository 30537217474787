import { Component, OnInit } from '@angular/core';

import { DashboardService } from '@app/_services/dashboard/dashboard.service';
import { Module } from '@app/_models/module';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-v3-dashboard',
  templateUrl: './v3-dashboard.component.html',
  styleUrls: ['./v3-dashboard.component.css']
})
export class V3DashboardComponent implements OnInit {

  modules: Module[] = [];

  constructor(private dashboardService: DashboardService, private titleService: Title) {
    this.titleService.setTitle('Jenny - Dashboard');
  }

  ngOnInit(): void {
    this.dashboardService.getAllModulesAndStates().subscribe(data => {
      data.forEach((module, index) => {
        if (module.name.includes('playwright')) {
          this.modules.push(data[index]);
        }
      });
    });

    setTimeout(() => {
      window.location.reload();
    }, 300000);
  }

}
