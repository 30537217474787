<!-- <<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h2 class="h2">Jenny</h2>
</div>> -->

<div class="home-container">
    <div class="body-container info">
        <h3>Welcome to Jenny</h3>
        <br>
        <p>Jenny is a multifunctional tool which is still under construction.<br>
            At this moment Jenny is used for:<br>
            1. Generate packets<br>
            2. Dashboard <br>
            3. Search<br>
            4. FAQ<br>
        </p>
    </div>

    <div class="body-container quick-access">
        <h3>Quick tools</h3>
        <br>
        <app-v2-quick-tools></app-v2-quick-tools>
    </div>

    <div class="body-container information" *ngIf="information">
        <h3>Information</h3>
        <br>
        <p><b>Message created on {{information.created}} </b></p>
        <p> {{information.information}}</p>
    </div>

</div>