import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthenticationService } from './_services/authentication.service';
import { Observable } from 'rxjs';
import { UserObject } from './_models/user';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isV2 = false;
  currentUser$: Observable<UserObject>;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.checkIfV2();
    this.currentUser$ = this.authenticationService.currentUser;
  }

  checkIfV2() {
    this.router.events
      .pipe(filter((event: NavigationEnd) => event instanceof NavigationEnd))
      .subscribe(
        (route: NavigationEnd) =>
          (this.isV2 = route.url.includes('/v2/') || route.url === '/')
      );
  }
}
