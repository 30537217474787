<div class="body-container">
    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2 class="h2">History of the {{moduleName}} module</h2><br>

    </div>
    <div class="alert alert-light" role="alert" *ngIf='passFail.state'>This module has {{passFail.fail}} amount of
        failed test runs and {{passFail.pass}} amount of passed tests.
        This is a success ratio of {{100 - passFail.ratio}}%.
        <br>
        We try to aim for a 70% success ratio in the first phase.


    </div>
    <div class="alert alert-dark" role="alert">
        Reports are only kept for 3 days in Google Cloud Storage.
    </div>

    <div class="table-responsive">
        <table class="table table-hover table-bordered">
            <thead>
                <tr>
                    <th scope="col">Passed</th>
                    <th scope="col">Created date</th>
                    <th scope="col">Report</th>
                    <th scope="col">
                        <div *ngIf="!edit">
                            <span class="material-icons btn dashboard-btn" (click)="toggle()">
                                edit
                            </span>
                        </div>
                        <div *ngIf="edit">
                            <span class="material-icons btn dashboard-btn" (click)="toggle()">
                                cancel
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr [ngClass]="history.state ? 'status-passed' : 'status-failed'"
                    *ngFor="let history of histories; let i = index" id="table">
                    <td>{{history.state}}</td>
                    <td>{{history.updated_at | date: 'dd-MM-YYYY HH:mm:ss' }}</td>
                    <td><a href="{{history.report_url}}" target="_blank">Google report</a></td>
                    <td>
                        <div class="form-check padding" *ngIf="edit">
                            <input class="form-check-input position-static checkbox select" type="checkbox"
                                id="checkbox-{{history.id}}" value="option1" aria-label="..."
                                [attr.data-checklist-id]="history.id">
                        </div>

                        <div *ngIf="!edit">
                            <span class="material-icons btn dashboard-btn" (click)="delete(history.id)">
                                delete
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="delete-button" *ngIf="edit">
            <button type="button" class="btn btn-secondary" (click)="deleteSelectedRows()">Delete
                selected</button>
        </div>
    </div>

    <br><button type="submit" class="btn btn-secondary btn-submit" (click)="clearRatio()" *ngIf='passFail.state'
        title="This will also clear all reports of this module">Clear
        test pass fail
        ratio</button>
</div>
