import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  loginResponse;

  constructor(private http: HttpClient) { }

  sendMessage(arg) {
    return this.http.post(`${environment.apiUrl}/api/generate/`, arg, { responseType: 'text' });
  }

  getDefaultTemplate(arg) {
    return this.http.get(`${environment.apiUrl}/api/defaultTemplate/${arg}`, arg);
  }

  searchInfo(arg) {
    return this.http.post(`${environment.apiUrl}/api/info/`, arg);
  }

  getHomeInfo() {
    return this.http.get(`${environment.apiUrl}/api/info/home/`);
  }

  // Auth
  searchForMachine(machineName: string, env: string) {
    return this.http.post(`${environment.apiUrl}/api/info/machine`, { machine: machineName, env, user: this.returnUserAsBody() });
  }

  // Auth
  searchForDevice(imei: string, env: string) {
    return this.http.post(`${environment.apiUrl}/api/info/device`, { imei, env, user: this.returnUserAsBody() });
  }

  // Auth
  searchForMachineOnID(arg) {
    // return this.http.post(`${environment.apiUrl}/api/info/machine/id`, arg);
  }

  login(user: string, password: string, env) {
    return this.http.post(`${environment.apiUrl}/api/auth/login`, { username: user, password, env });
  }


  private returnUserAsBody() {
    const test: any = JSON.parse(localStorage.getItem('loginResponse'));

    return { bearer: test.token, customerId: test.user.customer_id };
  }
}

