import { Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { AuthenticationService } from '../../_services/authentication.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-v2-navigation-menu',
  templateUrl: './v2-navigation-menu.component.html',
  styleUrls: ['./v2-navigation-menu.component.css'],
})
export class V2NavigationMenuComponent implements OnInit {
  username$: Observable<string>;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.username$ = this.authenticationService.currentUser.pipe(
      filter((user) => !!user),
      map((user) => {
        return `${user.first_name.charAt(0)}${user.surname.charAt(0)}`;
      })
    );
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
