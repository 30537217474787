<div class="body-container">

    <div class=" btn-group btn-devices btn-header scrollmenu" role="group" aria-label="Basic example">
        <div *ngFor="let device of devices; let i = index" class="button btn-header ">
            <button id="btn-{{device}}" type="button" (click)="switchDevice($event)"
                [ngClass]="clickedDevice == device? 'jenny-button active' : 'jenny-button'"> {{device}}</button>
        </div>

    </div>

    <div class="device-title">
        <h3>{{device?.model}}</h3>
    </div>

    <div class="">
        <div *ngIf='device?.model != undefined'>
            <form class="" [formGroup]="generateForm">

                <div class='generator-container'>
                    <div class='column'>
                        <div class="btn-group env" role="group" aria-label="Basic example">
                            <div *ngFor="let env of environments; let i = index" class="button">
                                <button id="btn-{{env}}" class="btn-env" type="button"
                                    (click)="selectEnvironment($event)"
                                    [ngClass]="clickedEnv == env ? 'jenny-button env active' : 'jenny-button env'">{{env}}</button>
                            </div>

                        </div>
                        <div>
                            <input class="form-control form-input" id="form-imei" placeholder="imei *"
                                formControlName="imei">
                            <input class="form-control form-input" type="number" id="form-latitude"
                                placeholder="Latitude" formControlName="latitude">
                            <input class="form-control form-input" type="number" id="form-longitude"
                                placeholder="Longitude" formControlName="longitude">
                        </div>


                    </div>

                    <div class='column'>
                        <app-v2-input-button id='input_{{i}}' [name]="'Digital Input ' + (i+1)"
                            [parentForm]="generateForm" *ngFor="let input of device.inputs; let i = index"
                            (stateEvent)="inputEventHandler(i, $event)">
                        </app-v2-input-button>
                        <hr *ngIf="device.inputsAnalog.length > 0">

                        <div *ngFor="let input of device.inputsAnalog; let i = index" class="disabled">
                            <app-v2-input-button id='input_analog_{{i}}' [name]="'Analog Input ' + (i+1)"
                                [parentForm]="generateForm" (stateEvent)="inputEventHandler(i, $event)"
                                disabled>
                            </app-v2-input-button>
                        </div>

                        <div *ngFor="let input of device.customInputs; let i = index" class="">
                            <app-v2-input-button id='input_{{i}}' [name]="input" [parentForm]="generateForm"
                                (stateEvent)="inputEventHandler(i, $event)" disabled>
                            </app-v2-input-button>
                        </div>

                        <hr *ngIf="device.inputsAnalog.length > 0 || device.inputs.length > 0">

                        <app-v2-input-button id='random' [name]="'Random inputs'" [parentForm]="generateForm"
                            (stateEvent)="randomToggle($event)" disabled *ngIf="device['randomInputs'] == undefined">
                        </app-v2-input-button>

                        <!-- &nbsp; is only 'quick hack' to align toggles -->
                        <app-v2-input-button id='canEnabled' [name]="'CAN enabled&nbsp;&nbsp;&nbsp;&nbsp;'" [parentForm]="generateForm"
                            (stateEvent)="canToggle($event)" disabled *ngIf="device['canData']">
                        </app-v2-input-button>
                    </div>

                    <div class='column text'>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="7"
                            placeholder="{{placeHolderBodyJSON}}" *ngIf="device.editable"
                            formControlName="json"></textarea>

                        <div *ngIf="jsonError" class="alert alert-danger">
                            {{jsonError}}
                        </div>

                    </div>
                </div>

                <hr>
                <div class='jenny-row'>
                    <button type="submit" class="btn btn-secondary btn-submit" [disabled]="!generateForm.valid"
                        (click)="onSubmit(generateForm.value)">Send message</button>
                </div>

                <div *ngIf="response">
                    <br>
                    <div *ngIf="response" class="alert alert-success">
                        {{response.status}} - {{response.wasSend}}
                    </div>

                    <app-v2-faq-card [card]="'card1'" [title]="'Last sent message'" [response]="response.message">
                    </app-v2-faq-card>
                </div>
            </form>
        </div>
        <div *ngIf='device == undefined'>This model does not exist yet</div>

    </div>
    <div>
        <!-- <div id="collapseQuestions-1" class="collapse"> -->

        <!-- </div> -->

    </div>




</div>
