import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-v2-faq-subcategory-question-and-answer',
  templateUrl: './v2-faq-subcategory-question-and-answer.component.html',
  styleUrls: ['./v2-faq-subcategory-question-and-answer.component.css']
})
export class V2FaqSubcategoryQuestionAndAnswerComponent implements OnInit {
  @Input()
  questions;
  header;

  constructor() { }

  ngOnInit(): void {
    this.header = Object.keys(this.questions);
  }

}
