<div class="top">
    <img src="/assets/img/jenny.png" />
</div>


<div id="login" class="col-md-4 offset-md-4 mt-4">
    <div class="card">
        <h4 class="card-header">Welcome to Jenny</h4>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <div class="login">
                    <button [disabled]="loading" class="btn btn-primary">
                        <div *ngIf="!loading"> Login</div>
                        <div *ngIf="loading"> Processing</div>
                    </button>
                    <img class="gif" *ngIf="loading" src="/assets/gif/jenny3.gif" />

                </div>

                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>


            </form>
        </div>
    </div>
</div>